<template>
  <div class="container_margin">
    <!-- start of Description card -->
    <div class="card p-3 border shadow-sm rounded">
      <h4 class="text-center">
        <b>{{ "Legal AI Trial Copilot" }}</b>
      </h4>
      <p class="text-center">
        {{
          "Efficiently create concise case documents, such as pleadings and motions. Analyze the current case using pertinent precedents, evaluating strengths and weaknesses, as well as conducting witness analysis. Strategize every step of the case preparation process by simply clicking the button below."
        }}
      </p>
      <div class="text-center">
        <button class="btn btn-primary" @click="openLESInput()">
          {{ "Prepare for your Case" }}
        </button>
        <!-- <button class="btn btn-primary ms-3" @click="openLESFinalInput()">
          {{ "Prepare for Your Final Submission" }}
        </button> -->
      </div>
    </div>
    <!-- end of Description card -->

    <!-- start of Case Flow Result header -->
     <h5 class="mt-3">
      <b>
        {{ `Case Flow Result${ searched_data.length > 1 ? "s" : "" }` }}
      </b>
    </h5>

    <div class="row mt-2 mb-3">
      <div
        v-if="searched_data.length !== 0 || searchQuery.length !== 0"
        class="col-5"
      >
        <span id="searchCaseLaw" class="input-group">
          <input
            v-model="searchQuery"
            type="search"
            class="form-control"
            placeholder="Search by statement"
            aria-label="Search by statement"
            @input="onInput"
            @keyup.enter="searchClients"
          />
          <span
            class="input-group-text cursor-pointer"
            @click="searchClients"
          >
            <b-icon icon="search" font-scale="1.2" />
          </span>
        </span>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-7">
        <b-pagination
          class="mb-0 float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>
    <!-- end of Case Flow Result header -->

    <!-- start of History table -->
    <table class="table card border shadow-sm p-2 rounded table-card mt-2">
      <thead>
        <tr>
          <th scope="col" width="8%">{{ "ID" }}</th>
          <th scope="col" width="35%">{{ "Case" }}</th>
          <th scope="col" width="12%" class="text-center">
            {{ "Jurisdiction" }}
          </th>
          <th scope="col" width="12%" class="text-center">
            {{ "Created Date" }}
          </th>
          <th
            scope="col"
            width="12%"
            class="text-center"
            style="padding-right: 15px"
          >
            {{ "Created By" }}
          </th>
          <th
            scope="col"
            width="20%"
            class="text-center"
            style="padding-right: 20px"
            v-if="
              permissions.includes('nexlaw.les') &&
              permissions.includes('nexlaw.les_trial')
            "
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody>
        <PageLoader
          class="w-100"
          v-if="pageLoading"
          :loading="pageLoading"
        ></PageLoader>

        <div
          v-else-if="searched_data.length === 0"
          class="container_margin"
        >
          <div class="my-2 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Data, Kindly generate new case flow" }}
          </p>
        </div>

        <tr v-else v-for="(item, index) in searched_data" :key="index">
          <td width="8%"><p>{{ item.id }}</p></td>
          <td width="35%"><p>{{ item.client_case }}</p></td>
          <td width="12%" class="text-center">
            <p>
              {{
                item.jurisdiction
                  ? getJurisdictionKey(item.jurisdiction)
                  : "N/A"
              }}
            </p>
          </td>
          <td width="12%" class="text-center">
            <p class="text-muted">
              {{ item.created_at | formatDate }}
            </p>
          </td>
          <td width="12%" class="text-center">
            <p class="text-muted font-weight-bold">
              {{ item.creator.name | username }}
            </p>
          </td>
          <td
            width="20%"
            class="text-center"
            v-if="
              permissions.includes('nexlaw.les') &&
              permissions.includes('nexlaw.les_trial')
            "
          >
            <button
              class="btn btn-primary tall-button"
              @click="openCase(item.id, 'preTrial')"
              v-if="
                item.completedPerCentage >= 100 &&
                item.lesTrialCompletedPerCentage ==
                  'The trial process did not start yet'
              "
            >
              {{ "Open Pre-Trial Results" }}
            </button>

            <button
              class="btn btn-primary tall-button"
              @click="openCase(item.id, 'Trial')"
              v-else-if="
                item.completedPerCentage >= 200 &&
                item.lesTrialCompletedPerCentage >= 100
              "
            >
              {{ "Open Trial Results" }}
            </button>

            <button
              @click="openCase(item.id, 'preTrial')"
              v-else-if="
                item.completedPerCentage < 100 &&
                item.lesTrialCompletedPerCentage ==
                  'The trial process did not start yet'
              "
              class="btn btn-warning-outlined tall-button-load"
            >
              <span class="font-14">
                <b>Generating Pre-Trial Documents:</b>
              </span>
              <br />
              {{ Math.round(item.completedPerCentage) }} %
              <b-spinner label="Spinning" class="spinner" />
            </button>

            <button
              @click="openCase(item.id, 'Trial')"
              v-else-if="
                item.completedPerCentage >= 100 &&
                item.lesTrialCompletedPerCentage < 100
              "
              class="btn btn-warning-outlined tall-button-load"
            >
              <span class="font-14">
                <b>Generating Trial Documents:</b>
              </span>
              <br />
              {{ Math.round(item.lesTrialCompletedPerCentage) }} %
              <b-spinner label="Spinning" class="spinner" />
            </button>

            <!-- <button
              class="btn btn-primary tall-button"
              @click="openCase(item.id, 'postTrial')"
              v-if="item.completedPerCentage >= 100" >
              {{ "Open Post-Trial Results" }}
            </button> -->
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end of History table -->

    <div style="height: 2.5rem;">
      <b-pagination
        v-if="searched_data.length !== 0 && rows > 10"
        class="float-right mt-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        last-number
      ></b-pagination>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LES from "@/store/LES.js";
import { loopUpdate } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      pageLoading: true,
      searched_data: [],
      searchQuery: "",

      loop: false,
    };
  },

  methods: {
    getJurisdictionKey(jurisdiction) {
      try {
        var countryName = Object.keys(JSON.parse(jurisdiction))[0];
        return ["USA", "United States"].includes(countryName)
          ? "United States of America"
          : countryName;
      } catch (e) {}
      
      return jurisdiction == "USA"
        ? "United States of America"
        : jurisdiction;
    },

    onInput() {
      if (this.searchQuery.length === 0) {
        this.searchClients();
      }
    },

    searchClients() {
      this.currentPage = 1;
      this.pageLoading = true;
      this.searched_data = [];

      var queryObj = {
        page: this.currentPage,
      };
      if (this.searchQuery.length !== 0) {
        queryObj.client_case = this.searchQuery;
      }

      this.$router.push({
        query: queryObj,
      });
      this.loadData();
    },

    openCase(data, category) {
      this.$router.push({
        name: "LegalExpertSystemPreTrialResult",
        params: {
          id: data,
          category: category,
        },
      });
    },

    openLESInput() {
      this.$router.push({
        name: "LegalExpertSystemInput",
      });
    },
    openLESFinalInput(){
      this.$router.push({
        name: "LegalExpertSystemFinalInput",
      });
    },
    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        // console.log("DATA",DATA);
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);

          if (e.client_case) {
            e.client_case = e.client_case.replace(/<[^>]*>/g, "");
          }

          if (ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e)
          }

          if (e.completedPerCentage < 100) me.loop = true;
        })
      }

      loopUpdate(
        this,
        LES.GetLESHistory,
        `&page=${this.currentPage}${
          this.searchQuery.length > 0 ? `&client_case=${this.searchQuery}` : ""
        }`,
        processData
      )
    },
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != this.$route.query.page) {
        this.pageLoading = true;
        this.searched_data = [];

        var queryObj = {
          page: newPage,
        };
        var param = `&page=${newPage}`;
        if (this.searchQuery.length !== 0) {
          queryObj.client_case = this.searchQuery;
          param += `&client_case=${this.searchQuery}`;
        }

        this.$router.push({
          query: queryObj,
        });
        clearTimeout(this.loop);
        this.loadData();
      }
    },
  },

  created() {
    const page = this.$route.query.page;
    if (page) {
      this.currentPage = parseInt(page, 10);
      this.searched_data = [];
    }

    const client_case = this.$route.query.client_case;
    if (client_case) {
      this.searchQuery = client_case;
    }

    this.loadData();
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>

<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}
/* table */

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

.btn-warning-outlined {
  height: 60px;
}

.tall-button {
  height: max-content;
}

.tall-button-load {
  height: max-content;
}
</style>
